import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Nav from "../Components/Nav"
import SEO from "../Components/SEO"
import { getImage } from 'gatsby-plugin-image'

import './blog-post.scss';
import Footer from "../Components/Footer"

export default function Template({
  data // this prop will be injected by the GraphQL query we'll write in a bit
}) {
  const { markdownRemark: post } = data // data.markdownRemark holds your post data
  const image = getImage(post.frontmatter.thumbnail)
  let imageUrl = ''
  if (typeof window !== `undefined`) {
    imageUrl = `${window.location.origin}${image.images.fallback.src}`
  } else {
    imageUrl = image.images.fallback.src
  }
  return (
    <main>
      <SEO title={`${post.frontmatter.title}`} description={`${post.excerpt}`} ogImage={imageUrl} ogImageWidth={200} ogImageHeight={150}/>
      <Nav />
      <div className="blog-post-container" style={{paddingTop: '8rem', maxWidth: 950, margin: '0 auto'}}>
        <Helmet title={`Funovus - ${post.frontmatter.title}`} />
        <div className="blog-post">
          <h1 id="title">{post.frontmatter.title}</h1>
          <h3 id="date">{post.frontmatter.date}</h3>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </div>
      <Footer />
    </main>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      excerpt(pruneLength: 250)
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail {
          childImageSharp {
            gatsbyImageData(
              height: 125
              width: 200
            )
          }
        }
      }
    }
  }
`